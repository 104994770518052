
@font-face {
  font-family: "GilroyBlack";
  src: local("GilroyBlack"), 
  url("../public/fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url("../public/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "GilroySemiBold";
  src: local("GilroySemiBold"), url("../public/fonts/Gilroy-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"),url("../public//fonts/Gilroy-Medium.ttf");
}

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}
:root{
  --colors-border: #d7d4e1;
}
#root,
html,
body {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GilroyRegular";
}




p {
  font-family: "GilroyRegular";
  vertical-align: middle !important;
}